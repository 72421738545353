import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import './FileTypes.scss'
import data from '../../assets/data'
import { useDispatch } from "react-redux";
import allActions from '../../actions'
import { useNavigate } from 'react-router';


function FileTypes(props) {
    const images = data[0].image;
    const docs = data[0].document;
    const books = data[0].book;
    const archives = data[0].archive;
    const audios = data[0].audio;
    const videos = data[0].video;
    const mergedArrays = [].concat(images, docs, books, archives, audios, videos);
    const [newData, setNewData] = useState(mergedArrays)
    const [imageData, setImagesData] = useState(images);
    const [docData, setDocData] = useState(docs);
    const [bookData, setBookData] = useState(books);
    const [archiveData, setArchiveData] = useState(archives);
    const [audioData, setAudioData] = useState(audios);
    const [videoData, setVideoData] = useState(videos);
    const [formData, setFormData] = useState("")
    let firstInput = sessionStorage.getItem("firstInput") || ""
    let secondInput = sessionStorage.getItem("secondInput") || ""
    const [index, setIndex] = useState(mergedArrays.indexOf(firstInput))
    const [indexTwo, setIndexTwo] = useState(mergedArrays.indexOf(secondInput))
    const inputRef = useRef(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        inputRef.current.focus()
    }, [])

    const handlePillClick = (item) => {
        setIndex(mergedArrays.indexOf(item))
        setTimeout(() => {
            dispatch(allActions.inputActions.inputFile([]))
            sessionStorage.setItem("firstInput", item)
            props.setConvertTo && props.setConvertTo(item)
            props.setShowFileTypes(false)
            props.setShowProgress && props.setShowProgress(true)
            navigate(`/${item}-converter`)
        }, 1)
    }

    const handlePillClickConvert = (item) => {
        sessionStorage.setItem("secondInput", item)
        setNewData(mergedArrays)
        setTimeout(() => {
            props.setConvertTo && props.setConvertTo(item)
            props.setShowFileTypesConvert(false)
            props.setShowProgress && props.setShowProgress(true)
        }, 1)
    }



    const handlePillClickTwo = (item) => {
        setIndexTwo(mergedArrays.indexOf(item))
        setTimeout(() => {
            sessionStorage.setItem("secondInput", item)
            props.setShowFileTypesTwo(false)
            if (firstInput) {
                window.open(`/${firstInput}-${item}`, '_self')
            } else if (!firstInput) {
                return
            }
        }, 10)
    }

    const handleSearch = (extension) => {
        let filteredData = mergedArrays.filter((item) => {
            if (item.toLowerCase().includes(extension.toLowerCase())) {
                return item;
            }
        })
        formData.length && setNewData(filteredData)
    }

    const handleInput = (event) => {
        const { value } = event.target;
        setFormData(value)
        handleSearch(value)
    }


    const [showImageTypes, setShowImageTypes] = useState(false)
    const [showDocTypes, setShowDocTypes] = useState(false)
    const [showBookTypes, setShowBookTypes] = useState(false)
    const [showArchiveTypes, setShowArchiveTypes] = useState(false)
    const [showAudioTypes, setShowAudioTypes] = useState(false)
    const [showVideoTypes, setShowVideoTypes] = useState(false)
    const [showAllPills, setShowAllPills] = useState(true)

    const imagePills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (imageData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))
    const docPills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (docData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))
    const bookPills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (bookData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))
    const archivePills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (archiveData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))
    const audioPills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (audioData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))
    const videoPills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (videoData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))
    const allPills = formData.length ? (newData.map((item, i) => <button style={i === index && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : i === indexTwo && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>)) : (newData.map((item, i) => <button style={item === firstInput && !props.showFileTypesTwo ? { backgroundColor: '#c1121f', color: 'white' } : item === secondInput && !props.showFileTypes ? { backgroundColor: '#c1121f', color: 'white' } : {}} className="pill-button" key={i} onClick={props.showFileTypes === true ? () => handlePillClick(item) : props.showFileTypesTwo === true ? () => handlePillClickTwo(item) : props.showFileTypesConvert === true ? () => handlePillClickConvert(item) : ''}>{item.toUpperCase()}</button>))

    const handleImageClick = () => {
        setShowImageTypes(true)
        setShowDocTypes(false)
        setShowBookTypes(false)
        setShowArchiveTypes(false)
        setShowAudioTypes(false)
        setShowVideoTypes(false)
        setShowAllPills(false)
    }

    const handleDocClick = () => {
        setShowImageTypes(false)
        setShowDocTypes(true)
        setShowBookTypes(false)
        setShowArchiveTypes(false)
        setShowAudioTypes(false)
        setShowVideoTypes(false)
        setShowAllPills(false)
    }

    const handleBookClick = () => {
        setShowImageTypes(false)
        setShowDocTypes(false)
        setShowBookTypes(true)
        setShowArchiveTypes(false)
        setShowAudioTypes(false)
        setShowVideoTypes(false)
        setShowAllPills(false)
    }

    const handleArchiveClick = () => {
        setShowImageTypes(false)
        setShowDocTypes(false)
        setShowBookTypes(false)
        setShowArchiveTypes(true)
        setShowAudioTypes(false)
        setShowVideoTypes(false)
        setShowAllPills(false)
    }

    const handleAudioClick = () => {
        setShowImageTypes(false)
        setShowDocTypes(false)
        setShowBookTypes(false)
        setShowArchiveTypes(false)
        setShowAudioTypes(true)
        setShowVideoTypes(false)
        setShowAllPills(false)
    }

    const handleVideoClick = () => {
        setShowImageTypes(false)
        setShowDocTypes(false)
        setShowBookTypes(false)
        setShowArchiveTypes(false)
        setShowAudioTypes(false)
        setShowVideoTypes(true)
        setShowAllPills(false)
    }

    return (
        <div className="box" >
            <div className="column-one">
                {!formData.length && <table className="file-types-list">
                    <tr onMouseOver={handleImageClick}><td>Image </td></tr>
                    <tr onMouseOver={handleDocClick}><td>Document </td></tr>
                    <tr onMouseOver={handleBookClick}><td>Book </td></tr>
                    <tr onMouseOver={handleArchiveClick}><td>Archive </td></tr>
                    <tr onMouseOver={handleAudioClick}><td>Audio </td></tr>
                    <tr onMouseOver={handleVideoClick}><td>Video </td></tr>
                </table>}
            </div>
            <div className="column-two" style={formData.length >= 2 ? { width: '24rem' } : {}}>
                <div className="search-bar-box">
                    <input className="input-box" type="text" placeholder="Search Format" ref={inputRef} id="search" value={formData} onChange={(e) => { setFormData(e.target.value); handleSearch(e.target.value) }} maxLength={10} />
                    <hr className="hr-line" />
                </div>
                {showAllPills && (allPills)}
                {showImageTypes && (imagePills)}
                {showDocTypes && (docPills)}
                {showBookTypes && (bookPills)}
                {showArchiveTypes && (archivePills)}
                {showAudioTypes && (audioPills)}
                {showVideoTypes && (videoPills)}
            </div>
        </div>
    )
}

export default FileTypes;